<template>
  <div class="container">
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/lcicon.png" alt="" class="icon" />
          <span>企业认证流程</span>
        </div>
        <div class="main textcenter">
          <img :src="lct" alt="" style="margin: 30px auto 10px; width: 60%" />
        </div>
      </div>
    </div>
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/zlicon.png" alt="" class="icon" />
          <span>资料填写</span>
        </div>
        <div class="main2">
          <el-form
            ref="form"
            :model="form"
            label-width="115px"
            label-position="left"
      
          >
            <el-form-item label="公司名称：" >
              <el-input v-model="form.company_name" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系人：" >
              <el-input v-model="form.name"></el-input>
            </el-form-item>

            <el-form-item label="联系电话：" >
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="公司地址：" >
               <get-area @input="setarea"></get-area>
            </el-form-item>

            <el-form-item label="详细地址：" >
              <el-input v-model="form.company_address"></el-input>
            </el-form-item>
            <el-form-item label="业务方向：" >
              <el-select v-model="form.yw_fx" placeholder="请选择业务方向">
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, i) in ywfx"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="行业类型：" >
              <el-select v-model="form.hy_type" placeholder="请选择行业类型">
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, i) in hylx"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经营业绩：" >
              <el-input v-model="form.yj"></el-input>
            </el-form-item>
            <el-form-item label="过往案例：">
              <el-input
                type="textarea"
                v-model="form.anli"
                rows="5"
                placeholder="请输入公司过往案例"
              ></el-input>
            </el-form-item>
            
            <el-form-item label="补充图片：">
             <upload-img @input="getimg"></upload-img>
            </el-form-item> 
            <el-form-item label="是否支持现场勘察：" label-width="180px">
              <el-radio-group v-model="form.can_kc">
                <el-radio :label="0">不支持</el-radio>
                <el-radio :label="1">支持</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="flex flexcenter">
              <el-button type="primary" @click="onSubmit">提交认证</el-button>
            </div>
            <div class="flex bottom1 justify-center" style="margin-top: 13px">
              <el-checkbox v-model="checked" class="remember"
                >我已阅读并同意<span
                  class="blue"
                  @click="openpage('aboutus', 'rzxy')"
                  >《认证协议》</span
                >。</el-checkbox
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import uploadImg from "@c/common/uploadimg.vue";
import getArea from "@c/common/area.vue";
export default {
  data() {
    return {
      form: {
        company_name: "",
        company_ssc: "", //公司地区, 省市区 拼接字符串 使用 单个空格拼接
        company_address: "",
        name: "",
        phone: "",
        hy_type: "", //行业类型
        yw_fx: "", //行业方向
        yj: "",
        anli: "",
        anli_images: [],
        can_kc: 0, //实地考察:0=不支持,1=支持
      },
      
      ywfx: [],
      hylx: [],
      checked: false,
      
      disabled: false,
      lct: "",
    };
  },
  components: {uploadImg,getArea},
  computed: {
    ...mapState(["isLogin", "userInfo"]),
  },
  mounted() {
     this.$nextTick(()=>{
       this.scrollToTop();
    })
    this.$http.topimagebyweb().then((res) => {
      this.lct = res.data.web_qyrz_lct;
    });
  
    this.$http.getywfx().then((res) => {
      this.ywfx = res.data;
    });
    this.$http.gethylx().then((res) => {
      this.hylx = res.data;
    });
  },
  methods: {
    ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
    ]),
     scrollToTop(){
         window.scroll({ top: 0, left: 0});
     },
    setarea(str){
    
      this.form.company_ssc=str;
    },
    getimg(str){
      // console.log(str)
      this.form.anli_images=str.split(",");
    },
    
    onSubmit() {
    
      if (!this.checked) {
        this.$message.error("请查看并勾选相关协议！");
        return;
      }

      this.$http.authcompany(this.form).then((res) => {
        if (res.code ==0) {
          if(res.msg.indexOf("必填项")>0){
            this.$message.error("全部必填，您还有信息没有填哦！");
          }else{
             this.$message.error({ message: res.msg });
          }
         
        } else if (res.code == 1) {
          this.$message.success({ message:  res.msg });
         this.openpage("/index");
        }
      });
    },
    openpage(path, id) {
      this.$router.push({
        path,
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container {
  margin-bottom: 20px;
}
.block {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.blockstyle {
  flex: 1;
  padding: 10px 15px;
  border-radius: 2px;
  background-color: #fff;
  .toptitle {
    position: relative;
    width: 100%;
    padding-bottom: 10px;
    font-weight: bold;
    &:after {
      content: "";
      position: absolute;
      left: -14px;
      right: -15px;
      bottom: 0;
      height: 1px;
      background-color: $color-theme;
    }
    .more {
      float: right;
      font-size: 18px;
      display: flex;
      align-items: center;
      transition: all 1s linear;
      cursor: pointer;
      &:hover {
        color: $color-theme;
      }
      img {
        margin-left: 10px;
        margin-top: 2px;
      }
    }
    .icon {
      margin-right: 10px;
      transform: translateY(3px);
    }
  }
}
.main2 {
  padding: 30px 140px;
}
.el-button {
  font-size: 16px;
  width: 200px;
  margin-top: 30px;
}
/deep/.el-radio__label {
  font-size: 18px;
}

.el-form {
  /deep/.el-form-item__label {
    font-size: 18px;
    color: #333;
  }
}
.el-select {
  width: 100%;
}


</style>
